@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Raleway:wght@400;500&display=swap');

$font-family-body: 'Raleway', sans-serif;
$font-family-heading: 'Playfair Display', serif;
$colour-light-grey: #F9F8F8;
$colour-primary: #C0D5E0;
$colour-primary-dark: #1B2D37;
$colour-secondary: #00399e;
$colour-secondary-light: #148ac6;
$box-shadow: 0 4px 6px rgba(159, 191, 208, 0.25);
$transition: ease 0.35s;

:root {
    font-size: 10px;
}

html {
    position: relative;
    min-height: 100%;
}

.header {
    max-width: 405px;
    margin: 0 auto;
    text-align: center;
}

.title {
    font-size: 6.4rem;
    margin: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-family: $font-family-heading;

    img {
        margin-right: 1rem;
        width: 48px;
    }
}

a {
    &:link, &:visited {
        transition: color $transition;
        color: $colour-secondary;
        text-decoration: none;
        font-weight: 700;
    }

    &:hover, &:focus {
        color: $colour-secondary-light;
    }
}

body {
    font-family: $font-family-body;
    background-color: $colour-light-grey;
    background-image: url("../pattern.png");
    font-size: 1.6rem;
    margin: 0;
    color: $colour-primary-dark;
}

main, footer {
    max-width: 95vw;
    margin: 0 auto;
    @media screen and (min-width: 1200px) {
        max-width: 1050px;
    }
}

main {
    min-height: 95vh;
    flex-grow: 1;
    @media (min-width: 1200px) {
        max-width: 800px;
    }
}

footer {
    font-size: 1.4rem;
    margin-top: 5rem;
    @media screen and (min-width: 1024px) {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
    }
}

.hidden {
    display: none;
}

button {
    padding: 0;
    background: none;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: $font-family-body;
    font-size: 1.4rem;

    &:focus {
        outline: none;
    }
}

.button {
    color: $colour-primary-dark;
    font-weight: 500;
    position: relative;
    padding: 10px 15px;

    span {
        z-index: 10;
        display: block;
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        transition: transform $transition;
    }

    &:hover, &:focus {
        &::before {
            transform: scale(1.05, .9);
        }
    }

    &--primary {
        &::before {
            background: linear-gradient(360deg, #8EB3C7 0%, $colour-primary 100%);
            box-shadow: inset 0 0 5px 1px #4F84A1;
        }
    }

    &--secondary {
        &::before {
            background: linear-gradient(180deg, hsl(0deg 0% 97%) 0%, hsl(0deg 0% 82%) 100%);
            box-shadow: inset 0 0 5px 1px hsl(0deg 0% 77%);
        }
    }
}

.config-container {
    background-color: #F5F8FA;
    box-shadow: $box-shadow;
    padding: 0.1rem 2rem;
    margin: 2rem 0 1rem;
    border-radius: 10px;
}

.form {
    text-align: left;

    &__button-ctn {
        display: flex;
        justify-content: space-between;
        max-width: 85%;
        margin: 0 auto;
    }

    &__input-ctn {
        margin: 2rem 0;
        label {
            font-size: 1.8rem;
            font-weight: 700;
        }

        input {
            font-size: 1.6rem;
            border: 1px solid $colour-primary-dark;
            border-radius: 5px;
            width: 50px;
        }
    }
}

.copy-recipe-ctn {
    margin-top: 2rem;
}

.copy-button {
    font-weight: bold;
    color: $colour-secondary;
    transition: color $transition;
    margin-left: 3rem;

    &:hover, &:focus {
        color: $colour-secondary-light;
    }
}

.tooltip {
    font-size: 1.1rem;
    opacity: 0;
    transition: opacity $transition;
    font-weight: 700;
    margin-left: 1rem;

    &--is-active {
        opacity: 1;
    }
}

.recipe__header {
    display: flex;
    align-items: center;
}

.recipe__name h2 {
    font-family: $font-family-heading;
    font-size: 3.6rem;
    margin: 1rem 0;
}

.recipe__steps ol {
    list-style-type: none;
}
